<template>
  <nav class="navbar navbar-light template-nabar full-height">
    <div class="nav-logo-image flexBox">
      <div class="">
        <img class="logo-image-style" src="@/assets/nepalLogo.jpg" alt="">
      </div>
      <div class="clearfix hack logo" style="text-align:left; margin-left: 71px;">
        <p style="margin-top: 0px;" v-if="lang_is_Eng.value==true">
          <span class="nav-bar-font" style="color:rgb(255, 255, 255); font-size:17px">Government of Nepal</span><br>
          <span class="nav-bar-font"
                style="color:rgb(255, 255, 255); font-size:15px">Ministry of Forests and Environment</span><br>
          <strong><span class="nav-bar-font"
                        style="color:rgb(255, 255, 255); font-size:20px">Department of Environment</span></strong><br>
          <span class="nav-bar-font"
                style="color:rgb(255, 255, 255); font-size:15px">Babarmahal, Kathmandu, Nepal</span>
        </p>
        <p style="margin-top: 0px;" v-else>
          <span class="nav-bar-font" style="color:rgb(255, 255, 255); font-size:17px">नेपाल सरकार</span><br>
          <span class="nav-bar-font"
                style="color:rgb(255, 255, 255); font-size:15px">वन तथा वातावरण मन्त्रालय</span><br>
          <strong><span class="nav-bar-font"
                        style="color:rgb(255, 255, 255); font-size:20px">वातावरण विभाग</span></strong><br>
          <span class="nav-bar-font" style="color:rgb(255, 255, 255); font-size:15px">बबरमहल, काठमाडौ</span>
        </p>
      </div>
    </div>
    <a class="navbar-brand flexBox" href="" v-if="lang_is_Eng.value==true"> Air Quality Watch</a>
    <a class="navbar-brand flexBox" href="" v-else> वायु प्रदूषण अनुगमन</a>
    <div class="col-md-5 flexBox infoIconAbout">
      <el-button type="primary" @click="centerDialogVisible = true">
        <font-awesome-icon icon="info-circle"/>
      </el-button>
      <div class="language-div">
        <el-switch
            v-model="lang_is_Eng.value"
            class="mb-2"
            active-text="English"
            inactive-text="Nepali"
            @change="LanguageChange()"
        />
      </div>
    </div>
    <el-dialog
        title="Air Quality Information System"
        v-model="centerDialogVisible"
        width="75%"
        center>
      <div class="about-body">
        <h3 class="headingPara">About</h3>
        <p class="aboutDetPara">
          Air Quality Watch – Nepal is a dashboard which brings publicly available air quality information from multiple
          sources like ground-based observations, satellite based remote sensing observations, air quality model data,
          and emission inventory all in one digital platform. Technical aspects of this air quality dashboard is
          supported by
          <el-link type="primary" href="https://www.icimod.org/initiative/atmospheric-watch-initiative/">Atmospheric
            Watch Initiative (AWI)
          </el-link>
          and
          <el-link type="primary" href="https://servir.icimod.org">SERVIR-HKH Initiative
          </el-link>
          of the International Centre for
          Integrated Mountain Development (ICIMOD). The Department will work to continuously improve the dashboard in
          collaboration with various partners and agencies.
        </p>
        <h3 class="headingPara">Acknowledgement for Current Data Source</h3>
        <ul>
          <li> Ground-based observation –
            <el-link type="primary" href="https://pollution.gov.np/portal/#/home">Atmospheric
              Department of Environment Nepal
            </el-link>
            ,
            <el-link type="primary" href="https://www.airnow.gov/international/us-embassies-and-consulates/"> AirNow
            </el-link>
          </li>
          <li>Satellite products –
            <el-link type="primary" href="https://modis.gsfc.nasa.gov/data/"> MODIS
            </el-link>
            ,
            <el-link type="primary" href="https://sentinel.esa.int/web/sentinel/sentinel-data-access"> TROPOMI
            </el-link>
          </li>
          <li>Air quality models –
            <el-link type="primary" href="https://gmao.gsfc.nasa.gov/weather_prediction/GEOS-CF/data_access/"> GEOS-CF
            </el-link>
          </li>
          <li>Emission inventory –
            <el-link type="primary" href="https://www.nies.go.jp/REAS/"> REAS
            </el-link>
            ,
            <el-link type="primary" href="https://edgar.jrc.ec.europa.eu/dataset_htap_v2"> EDGAR-HTAP
            </el-link>
            ,
            <el-link type="primary" href="https://gains.iiasa.ac.at/models/"> GAINS
            </el-link>
          </li>
        </ul>

        <h3 class="headingPara">Pollutants</h3>

        <ul>
          <li> Particulate Pollutants – PM2.5, PM10, PM1, TSP, AOD</li>
          <li>Gaseous Pollutants – CO, NOx, SO2, O3</li>
        </ul>
        <h3 class="headingPara">Data Categories</h3>
        <ul>
          <li>Recent (Last 24 hours)</li>
          <li>Archive (Past 1 week)</li>
          <li>Forecast (Next 48 hours),</li>
          <li>Emission Inventory (Annual)</li>
        </ul>


        <h3 class="headingPara">Contact</h3>
        <ul>


          <li>Deepak Gyawali</li>
          <li>Senior Divisional Chemist</li>
          <li>Department of Environment</li>
          <li>Ministry of Forest and Environment</li>
          <li>Government of Nepal</li>
          <li>email-gyawalideep@gmail.com</li>

        </ul>

        <h3 class="headingPara">Disclaimer!</h3>
        <ul>
          <li>
            Quality of the datasets are attributed to original data provider quality assurance/quality control.
            Additional datasets continue to be added as and when they are readily available.
          </li>
        </ul>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancel-dialog" @click="centerDialogVisible = false">Cancel</el-button>
      </span>
      </template>
    </el-dialog>
  </nav>
</template>

<script>
import {mapState} from "vuex";
import {setParamEnglish} from '../utils/LanguageParameter'
export default {
  name: "NavBar",
  data() {
    return {
      centerDialogVisible: false,
    };
  },
  computed: {
    ...mapState(["lang_is_Eng"]),
  },
  methods:{
    LanguageChange(){

      setParamEnglish(this.lang_is_Eng.value);
      location.reload();

    }
  }
}
</script>

<style scoped>
.template-nabar {
  background-color: #207b20;
  overflow: hidden;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flexBox {
  flex: 33.33%
}

.full-height {
  height: 100%;
}

.template-nabar .navbar-brand {
  /* padding-bottom: .3125rem; */
  /* margin-right: 1rem; */
  font-size: 1.7rem;
  /* line-height: inherit; */
  /* white-space: nowrap; */
}

.template-nabar a.navbar-brand {
  color: white !important;
}

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.navbar-brand {
  margin-left: 1rem;
}

.nav-logo-image {
  margin-right: 15px;
  margin-top: 2px;
  height: inherit;
}

.logo-image-style {
  height: 75px;
  float: left;
}

.nav-bar-font {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-feature-settings: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variation-settings: normal;
  line-height: 1.2;
}

.navbar-brand.flexBox {
  text-align: center;
}

.col-md-5.flexBox.infoIconAbout .el-button {
  border-color: #569b57;
}

.headingPara {
  font-weight: bold;
}

.aboutDetPara {
  text-align: justify;
}

.about-body ul {
  list-style-type: none;
}

.language-div {
  padding: 10px 22px 5px 10px;
  float: right;
}
/*.about-body p,.about-body li{*/
/*  word-break: break-word;*/
/*}*/


</style>

