<template>
  <div class="card-box full-height full-width about-div">
    <div class="pannel-title">
      <h6 class="title-heading">{{ lang_is_Eng.value == true ? 'Map Controls' : 'नक्सा नियन्त्रण' }}
      </h6>
    </div>
    <div class="body-container">
      <el-scrollbar height="100%">
        <el-form ref="form" :model="form" label-width="auto" :inline="getIsInlineFormorNot" size="mini"
                 label-position="left">

          <!--        <el-form-item label="Region">-->
          <!--          <el-select v-model="form.region" placeholder="Select region" class="full-width">-->
          <!--            <el-option-->
          <!--                v-for="item in data.region"-->
          <!--                :key="item.value"-->
          <!--                :label="item.label"-->
          <!--                :value="item.value">-->
          <!--            </el-option>-->
          <!--          </el-select>-->
          <!--        </el-form-item>-->

          <el-form-item :label="lang_is_Eng.value==true?'Inventory':'सूची'" class="full-width">
            <el-select v-model="form.inventory" placeholder="Select" @change="updateMapLayer()" class="full-width">
              <el-option
                  v-for="item in data.Inventory"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="lang_is_Eng.value==true?'Pollutants':'प्रदुषण'" class="full-width">
            <el-select v-model="form.pollutants" placeholder="Select" @change="updateMapLayer()" class="full-width">
              <el-option
                  v-for="item in data.pollutants"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="lang_is_Eng.value==true?'Sectors':'क्षेत्र'" class="full-width">
            <el-select v-model="form.sectors" placeholder="Select" @change="updateMapLayer()" class="full-width">
              <el-option
                  v-for="item in data.sectors"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="lang_is_Eng.value==true?'year':'वर्ष'" class="full-width">
            <el-select v-model="form.year" placeholder="Select" @change="updateMapLayer()" class="full-width">
              <el-option
                  v-for="item in data.year"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="submit-btn full-width">
            <!--          <el-button type="primary" class="full-width">Get Emission Plot</el-button>-->
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import {dataSelect} from '../utils/dataEmission'
import eventHub from "../utils/utils";
import {mapActions, mapState} from 'vuex';
import GeoJSON from "ol/format/GeoJSON";
// import {getCenter as ol_extent_getCenter} from "ol/extent";

export default {
  name: "mapControlsEmission",
  data() {
    return {
      form: {
        region: '',
        pollutants: '',
        periodicity: '',
        year: '',
        sectors: '',
        inventory: ''
      },
      isInlineForm: true,
      data: dataSelect
    }
  }, methods: {
    ...mapActions(["GETCountryGeojson"]),
    async onSubmit() {

    },

    getInLineData() {
      return this.isInlineForm
    },

    updateMapLayer() {
      this.$store.state.pollutants = this.form.pollutants;
      this.$store.state.sectors = this.form.sectors;
      this.$store.state.year = this.form.year;
      this.$store.state.inventory = this.form.inventory;
      eventHub.$emit("InventoryChange");
    },
    async setDefaultMap() {


      this.form.pollutants = 'PM2p5';
      this.form.sectors = 'Total';
      this.form.year = '2015';
      this.form.inventory = 'REAS';

      // Javasctipt API Stack
      setTimeout(() => {
        this.updateMapLayer();
      }, 0);
      let geoJson = await this.GETCountryGeojson();
      // let geoJson = {
      //   type: "FeatureCollection",
      //   features: [
      //     {
      //       type: "Feature", properties: {},
      //       geometry: {
      //         type: "Polygon",
      //         coordinates: [
      //           [
      //             [
      //               85.1495361328125,
      //               28.04289477256162
      //             ],
      //             [
      //               84.869384765625,
      //               27.746746268526383
      //             ],
      //             [
      //               84.9462890625,
      //               27.298570715261533
      //             ],
      //             [
      //               85.6549072265625,
      //               27.28880751983314
      //             ],
      //             [
      //               85.836181640625,
      //               27.518015241965667
      //             ],
      //             [
      //               85.84716796875,
      //               27.702983735525862
      //             ],
      //             [
      //               85.67138671875,
      //               28.00895171255095
      //             ],
      //             [
      //               85.374755859375,
      //               28.091366281406945
      //             ],
      //             [
      //               85.1495361328125,
      //               28.04289477256162
      //             ]
      //           ]
      //         ]
      //       }
      //     }
      //   ]
      // };
      console.log("geoJson");
      console.log(geoJson);
      // this.vectorLayerSource.
      var format = new GeoJSON();

      var f = format.readFeature(geoJson.features[0], {
        dataProjection: "EPSG:4326",
        featureProjection: this.mapObject.getView().getProjection()
      });
      this.vectorLayerSource.addFeature(f);
      this.selectInteractionObj.getFeatures().push(f);
      this.selectInteractionObj.dispatchEvent({type: 'select'});
      // Zoom to map centre*****
      // var view = this.mapObject.getView();
      // var resolution = view.getResolutionForExtent(f.getGeometry().getExtent(), this.mapObject.getSize());
      // var zoom = view.getZoomForResolution(resolution);
      // var center = ol_extent_getCenter(f.getGeometry().getExtent());
      // // redraw before zoom
      // setTimeout(function () {
      //   view.animate({
      //     center: center,
      //     zoom: Math.min(zoom, 16)
      //   });
      // }, 100);

      // Zoom to map centre*****


      eventHub.$emit('setDefaultCascaterForChart', 1, 'SectorShare', ["REAS", "PM2p5", "2015"]);
      eventHub.$emit('setDefaultCascaterForChart', 2, 'SectorContribution', ["REAS", "PM2p5", "2015"]);
      eventHub.$emit('setDefaultCascaterForChart', 3, 'Timeseries', ["REAS", "PM2p5", "Total"]);
      eventHub.$emit('setDefaultCascaterForChart', 4, 'InventriesComparison', ["PM2p5", "Total", "2015"]);

      setTimeout(() => {
        eventHub.$emit('setDefaultEmissionStatistics');
      }, 1000)
    },
  },
  computed: {
    ...mapState(["vectorLayerSource", "mapObject", "selectInteractionObj", "lang_is_Eng"]),
    getIsInlineFormorNot() {
      return this.getInLineData();
    },
  },
  created() {
    this.form.region = this.$store.state.region;
    this.form.pollutants = this.$store.state.pollutants;
    this.form.sectors = this.$store.state.sectors;
    this.form.year = this.$store.state.year;
  },

  mounted() {
    // Generate the chart
    // eslint-disable-next-line no-undef
    setTimeout(() => {
      this.setDefaultMap();
    }, 1000);
  },
}
</script>

<style scoped>
.body-container {
  height: calc(100% - 50px);
}
</style>