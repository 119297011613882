
export function isEnglish() {
    let IsEnglish = true;
    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);
    let a = queryParams.get("lang") + '';

    if (a.toLowerCase() == 'en') {
        IsEnglish = true;
    } else if (a.toLowerCase() == 'ne') {
        IsEnglish = false;
    }
    return IsEnglish;
}

export function setParamEnglish(boolValue) {
    var queryParams = new URLSearchParams(window.location.search);
    if (boolValue) {
        queryParams.set("lang", "en");
    } else {
        queryParams.set("lang", "ne");
    }
    history.replaceState(null, null, "?" + queryParams.toString());
}
