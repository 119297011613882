export var TethysAppName = "airqualitynp";

export var TethysAPIAppName = "airqualitynp";

// export let threddDataSource = 'http://smog.spatialapps.net:8080/thredds/';
export let threddDataSource = 'http://110.44.114.233:8080/thredds/';

let GeoServerHostLVar = null;
let hostName=window.location.hostname;

if (process.env.NODE_ENV === "production") {
    GeoServerHostLVar = 'http://'+hostName+':8080';
    // GeoServerHostLVar = 'http://smog.spatialapps.net:8080';
} else {
    // GeoServerHostLVar = 'http://smog.spatialapps.net:8080';
    GeoServerHostLVar = 'http://192.168.10.85:8080';
}

export let GeoServerHost = GeoServerHostLVar;

export var DefaultMaskWMS = false;

export var DefaultPlotInfo = {
    colorScheme: 'jet',
    BBOX: [60, 15, 110, 40],
    tickSpan: 10,
    Resolution: 600,
    width: 12,
    height: 9
}
